import Signature from 'signature';

window.form_signature = new Signature({
    modal_id: '#modal-create-form-signature',
    canvas_signature_pad_id: '#form-signature-pad',
    // Implemented custom handler to open the modal depending on validation
    open_signature_pad_selector: false,
    signature_pad_file_element: '.signature_pad_file',
    close_modal_after_save: true,
    selector_to_resolve_save_url_by_form: '.open-signature-pad-in-form',
    selector_to_resolve_config_by_data_attributes: '.open-signature-pad-in-form',
    log: true,
});

function open_signature_modal() {
    form_signature.openSignatureModal();
}

/**
 * First validate Form before opening Signature-Pads when "validate_form_on_signature_pad_open" is activated
 */
$(document).on('click', 'button.validate_form_on_signature_pad_open', function () {
    submit_form({
        form: $(this).closest('.generated-form'),
        suppress_feedback_modal: true,
        skip_post_processing: true,
        check_only: true,
        callback: open_signature_modal,
    });
});

/**
 * Prevent opening Signature-Pads when "validate_form_on_signature_pad_open" is activated
 */
$(document).on('click', 'button.open-signature-pad-in-form:not(.validate_form_on_signature_pad_open)', function (e) {
    open_signature_modal();
});
